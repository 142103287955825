<template>
  <div class="addProjectsafe">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{addPtitle}}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addProjects-content">

        <div class="addProjects-input">
          <span>材料名称</span>
          <div>
           <el-select style="width:100%" v-model="result" placeholder="请选择材料名称" @change="materChange">
                    <el-option
                    v-for="item in optionsMaterial"
                    :key="item.ID"
                    :label="item.materialName"
                    :value="item.ID">
                    </el-option>
                </el-select>
          </div>
        </div>

      </div>
      <div class="addProjects-content">

        <div class="addProjects-input">
          <span>材料规格</span>
          <div>
           <el-select style="width:100%" v-model="specifications"  placeholder="请选择材料规格">
                    <el-option
                    v-for="item in optionSpecifications"
                    :key="item.ID"
                    :label="item.specificationsContent"
                    :value="item.ID">
                    </el-option>
                </el-select>
          </div>
        </div>

      </div>

      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>供应单位</span>
          <div>
            <el-input v-model="inputs1"
                      maxlength='20'
                      show-word-limit
                      placeholder="请输入供应单位"></el-input>
          </div>
        </div>
      </div>

            <div class="addProjects-content">

        <div class="addProjects-input">
          <span>退料类型</span>
          <div>
           <el-select style="width:100%" v-model="ReturnType"  placeholder="请选择退料类型">
                    <el-option
                    v-for="item in materialReturnType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
          </div>
        </div>

      </div>

      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>退料人</span>
          <div style="width:160px">
            <el-select style="width:100%" v-model="roleNmae"  placeholder="请选择角色" @change="roleNmaeChange">
                    <el-option
                    v-for="item in optionsroleNmae"
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID">
                    </el-option>
                </el-select>
          </div>
          <div style="width:160px;margin-left:20px">
             <el-select style="width:100%" v-model="personnel"  placeholder="请选择人员">
                    <el-option
                    v-for="item in optionspersonnel"
                    :key="item.ID"
                    :label="item.UserName"
                    :value="item.ID">
                    </el-option>
                </el-select>
          </div>
        </div>
      </div>
       <div class="addProjects-content">
        <div class="addProjects-input">
          <span>退料时间</span>
          <div style="width:210px">
            <el-date-picker
              v-model="datevalue"
              type="date"
              placeholder="请选择时间"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>退料数量</span>
          <div>
            <el-input v-model="warehousingNum"
                      maxlength='20'
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      show-word-limit
                      placeholder="请输入退料数量"></el-input>
          </div>
        </div>
      </div>
      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>退料原因</span>
          <div>
             <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="50"
                    show-word-limit
                    :autosize="{minRows: 6, maxRows: 6 }"
                    ></el-input>
          </div>
        </div>
      </div>


      <!-- <div class="addProjects-content">

        <div class="addProjects-input addProjects-input02" style="align-items: baseline">
          <span>教育内容</span>
          <div>
            <div id="div1"
                 style="height:100%"></div>
          </div>
        </div>

      </div> -->

      <div class="addProjects-bottom">
        <button @click="closefn">取消</button>
        <button @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="(!result||!specifications || !inputs1 ||!roleNmae || !personnel || !datevalue || !warehousingNum || !ReturnType || !textarea)?'def':'' ">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import Ewang from "wangeditor";
import { baseUrl } from "@/js/util.js";

import { Insert_materialQuit, GetSecurityBigEvent ,getMateriaList,getSpecificationsList,Get_roleList,getStockDumpAreaList,Get_userForRoleid} from '@/js/safeDisclose'
export default {
  props: {
    addPfalse: Boolean,
    bonus: Number,
    addPtitle: String,
    editdata: Object,
    sqtype: Number,
  },
  setup (props, { emit }) {
    const state = reactive({
      newHtml: '',
      sqtype: '',
      ReturnType:'',
      textarea:'',
      materialReturnType:[
        {
            label:'库存退料',
            value:2
        },{
            label:'出库退料',
            value:1
        }
      ],
      optionsMaterial:[],
      optionSpecifications:[],
      roleNmae:'',
      optionsroleNmae:[],
      specifications:'',
      personnel:'',
      optionspersonnel:[],
      warehousingNum:'',
      locationYard:'',
      optionslocationYard:[],
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      result:'',
      datevalue:'',
      inputs2: "",
      inputs3: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      addPtitle: '',
      editid: '',
      num: 0,
      showstyle: false,
      filelist: [],
      fileimg: [],
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        }
      ],
    });
    const closefn = () => {
      emit("addPfalsefn");
    };

    const cdata = getStorage("bscdata");

    const methods = {

      // 图片
      beforeUpload: (file) => {

        const is1M = file.size / 1024 / 1024 < 3; // 限制小于3M

        if (!is1M) {
          ElMessage({
            showClose: true,
            message: '大小不可超过3M',
            type: "error",
          });

        }
        return is1M
      },
      // 上传图片
      successfn: (response, file, fileList) => {// 图片上传成功
        state.updatalistnum++;
        console.log(response, file, fileList);
        state.filelist = fileList;
        state.fileimg.push(response.join(','))
        console.log(state.fileimg);
        state.imglist = [];
      },
      handlePictureCardPreview: (file) => {// 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemove: (file, fileList) => {// 删除图片
      console.log(file,fileList);
        state.updatalistnum++;
        let datas = [...file.response];
        state.filelist = fileList;
        state.fileimg = []
        state.filelist.forEach(element => {
          state.fileimg.push(element.response.join(','))
        });
        state.imglist = [];
        // DeleteFile(datas);
      },

      // 添加
      UpdateSecurityBigEvent: () => {
        let datas = {}
          datas = {
            materialId:state.result,
            specificationsId:state.specifications,
            supplyUnit:state.inputs1,
            materialQuitPeopleId:state.personnel,
            materialQuitTime:state.datevalue,
            materialQuitNum:state.warehousingNum,
            materialQuitType:state.ReturnType,
            remark:state.textarea,
            insertUser: cdata.uid,
            projectID: cdata.pid,
          }

        

        Insert_materialQuit(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addPfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });

      },

      AddExtractApplyfn: () => {

        if (!state.result||!state.specifications || !state.inputs1 ||!state.roleNmae || !state.personnel || !state.datevalue || !state.warehousingNum || !state.ReturnType || !state.textarea) {
          return
        }

        methods.UpdateSecurityBigEvent()

      },
      getMateriaList: () =>{
        let params = {
          projectId:cdata.pid,
        }
        getMateriaList(params).then(res=>{
          if (res.data.Code == 1) {
            state.optionsMaterial = res.data.Data
          }
        })
      },
      materChange(id){
        state.specifications = '';
        let params = {
          materiaId:id
        }
        getSpecificationsList(params).then(res=>{
          if (res.data.Code == 1) {
            state.optionSpecifications = res.data.Data;
          }
        })
      },

      Get_roleList(){
        let params = {
          projectId:cdata.pid,
        }
        Get_roleList(params).then(res=>{
          if (res.data.Code == 1) {
            state.optionsroleNmae = res.data.Data;
          }
        })
      },
      roleNmaeChange(id){
        state.personnel = '';
        let params = {
          roleId:id
        }
        Get_userForRoleid(params).then(res=>{
           if (res.data.Code == 1) {
            state.optionspersonnel = res.data.Data;
          }
        })
      },

      // 堆场位置下拉
      getStockDumpAreaList(){
        let params = {
           projectId:cdata.pid,
        }
        getStockDumpAreaList(params).then(res=>{
          if (res.data.Code == 1) {
            state.optionslocationYard = res.data.Data;
          }
        })
      },

    };

    onMounted(() => {
      console.log(props)
      state.sqtype = props.sqtype;
      state.addPtitle = props.addPtitle;
      if (state.addPtitle == '编辑大事记') {

        state.editid = props.editdata.ID;
      }

      methods.getMateriaList();
      methods.Get_roleList();
      methods.getStockDumpAreaList();
    });

    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addProjectsafe {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 656px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 33px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 90px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    &:deep(#div1) {
        .w-e-text{
           p{
          b{
            font-weight: bold !important;
            }
          i{
            font-style:italic !important;
            }
      }
        }
       
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>