<template>
  <div id='safetyMemorabilia'>
    <div class="Implement-add">
      <div class="adds"
           @click="addfn(1)">添加退料</div>
    </div>

    <div class="Implement-wrap">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">材料名称</span>
        <span class="title-03">规格</span>
        <span class="title-04">供应单位</span>
        <span class="title-05">退料类型</span>
        <span class="title-06">退料人</span>
        <span class="title-07">退料时间</span>
        <span class="title-08">退料数量</span>
        <span class="title-09">退料原因</span>
      </div>

      <div class="Implement-list"
         v-if="listdata.length"
           v-for='(item,index) in listdata'
           :key="index">
        <span class="title-01 textOverflowHide">{{index+1}}</span>
        <span class="title-02 textOverflowHide">{{item.materialName}}</span>
        <span class="title-03 textOverflowHide">{{item.specificationsContent}}</span>
        <span class="title-04 textOverflowHide">
         {{item.supplyUnit}}
        </span>
        <span class="title-05 textOverflowHide">
         {{item.materialQuitType == 1?'出库退料':'库存退料'}}
        </span>
        <span class="title-06 textOverflowHide">
         {{item.UserName}}
        </span>
        <span class="title-07 textOverflowHide">
         {{item.materialQuitTime.slice(0,10)}}
        </span>
         <span class="title-08 textOverflowHide">
         {{item.materialQuitNum}}
        </span>
        <span class="title-09 textOverflowHide">
         {{item.remark}}
        </span>
      </div>
      <div class="notdata" v-else>
        暂无数据
      </div>
    </div>
    <div class="pages"
         v-if="total && total > pageSizes">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSizes"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>

    <addMaterialReturn v-if="addPfalse"
                  :sqtype='sqtype'
                 :addPtitle='addPtitle'
                 :editdata='editdata'
                 @addPfalsefn='addPfalsefn'
                 @GetProjectBigEventList='GetProjectBigEventList'></addMaterialReturn>
  <addwarehousImg
    v-if="FileType"
    :activeurn="activeurn"
    @close="close"
  ></addwarehousImg>
  </div>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import addMaterialReturn from '@/components/addMaterialReturn.vue'
import addwarehousImg from '@/components/addwarehousImg.vue'
import {Get_materialQuitPageList,DeleteSecurityBigEvent} from '@/js/safeDisclose'
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {DelPictureList} from "@/js/indexApi.js";
export default {
  name: '',
  props: {

  },

  setup (props) {

    const state = reactive({
      FileType:false,
      activeurn:'',
      baseUrl:baseUrl,
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addPfalse: false,
      addPtitle: '',
      listdata:[],
      editdata:'',
      sqtype:1,//安全：1，质量：2，
    });
    const cdata = getStorage("bscdata");

    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {
       
       
      });
    };

    const methods = {
      // 添加弹框
      addfn: (id,item) => {
        console.log(121212)
        if(id==1){
           state.addPtitle = '添加退料';
        }else if(id==2){
          state.addPtitle='编辑入库';
          state.editdata=item
          console.log(state.editdata,'state.editdata')
        }
       
        state.addPfalse = !state.addPfalse;
      },
      //添加弹框 关闭弹框
      addPfalsefn () {
        state.addPfalse = false;
      },
      close(){
         state.FileType = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GetProjectBigEventList()
      },

    // 列表
    
     GetProjectBigEventList:()=>{
        let datas={
          ProjectID:cdata.pid,
          pageIndex:state.currentPage,
          pageSize:state.pageSizes,
        }
        Get_materialQuitPageList(datas).then((res) => {
          console.log(res,'list');
          if (res.data.Code == 1) {
            state.listdata=res.data.Data.data;
            state.total=res.data.Data.totalCount.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });

      },
      dels(item){
         let datas={
     
          ID:item.ID,
         
        }
        DeleteSecurityBigEvent(datas).then((res) => {
          if (res.data.Code == 1) {
           ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
           let imgs=[]
            imgs.push(item.ImgUrl)
            delDoc(imgs)
            methods.GetProjectBigEventList()
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
        // 删除项目大事记
      delfn(item){
         ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            methods.dels(item)
          }).catch((error) => {
           

          })
       
      },
      preview(item){
        state.FileType = !state.FileType;
        state.activeurn = item.storageFiles.split(';')
      },



    };


    onMounted(() => {
          methods.GetProjectBigEventList()
    });

    return {
      ...methods,
      ...toRefs(state)
    };

  },

  components: {
    addMaterialReturn,
    addwarehousImg
  }


}

</script>

<style lang='scss' scoped>
#safetyMemorabilia {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 86px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
    height: 70px;
    line-height: 70px;
    span {
      i {
        &:nth-child(1),
        &:nth-child(2) {
          color: #0083ff;
        }

        &:nth-child(3) {
          color: #e7111b;
        }
      }
    }
    .title-04 {
    //   display: flex;
    //   // padding: 0;
    //   justify-content: space-around;
    }
  }
  .Implement-list {
    border-top: none;
    overflow: hidden;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
  }
  .Implement-list > span {
    line-height: 70px;
  }

  .title-01 {
    width: 4%;
  }
  .title-02 {
    width: 16%;
  }
  .title-03 {
    width: 15%;
  }
  .title-04 {
    width: 10%;
  }
  .title-05 {
    width: 10%;
  }
  .title-06 {
    width: 10%;
  }
  .title-07 {
    width: 10%;
  }
  .title-08 {
    width: 10%;
  }
  .title-09 {
    width: 15%;
  }


  .Implement-list > .title-02 {
    display: inline-block;
    line-height: 70px;
    width: 16%;
    height: 70px;
    position: relative;

    img {
      display: inline-block;
      width: 50px;
      height: 50px;
      -o-object-fit: contain;
      object-fit: contain;
      background: #d8d8d8;
      position: absolute;
      // left: 50%;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }


  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>